function MyLoader({isLoading}) {
  return (
      <div id="loader" className={(isLoading ? '' : 'hidden')}>
          <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
          </div>
      </div>
  );
}

export default MyLoader;