import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

function ChartPriceHistory({ dataPriceHistory }) {

    const myData = dataPriceHistory;
    const chartData = [];
    let counter = 0;
    //console.log("ChartMonthOverviewGroup", myData);
    if (myData) {
        for (let i = 0; i < Object.keys(myData).length; i++) {
            let d = {
                name: counter++,
                costs: parseFloat(myData[i])
            };
            chartData.push(d);
        }
    }

    console.log('dataPriceHistory', dataPriceHistory);
    console.log('chartData', chartData);

    const yAxisLabelFormatterWithCurrency = (value) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
    }

    return (
        <>
            <h4 className="mb-4">Preisentwicklung</h4>

            {/*
            <ResponsiveContainer width={'100%'} height={150}>
                <BarChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}                                        >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis dataKey="costs" />
                    <Tooltip />
                    <Legend />
                    <Bar
                        dataKey="costs"
                        fill="#002ab1"
                        barSize={20}
                        formatter={(value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)}
                    />
                </BarChart>
            </ResponsiveContainer>
            */}

            <ResponsiveContainer width={'100%'} height={150}>
                <LineChart
                    width={500}
                    height={150}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 5,
                        left: 5,
                        bottom: 5,
                    }} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                        //domain={['auto', 'auto']}
                        //reversed={true}
                        //padding={{ top: 5, bottom: 5 }}
                        tickFormatter={yAxisLabelFormatterWithCurrency}
                    />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="costs"
                        stroke="#8884d8"
                        activeDot={{ r: 5 }}
                        formatter={(value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)} />
                </LineChart>
            </ResponsiveContainer>
        </>
    )

}

export default ChartPriceHistory;