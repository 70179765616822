import { Helmet } from 'react-helmet';

function HtmlHeaderTags({ title, type, baseurl, teaserImage }) {
    console.log('HtmlHeaderTags', type);

    let description = '';
    switch (type) {
        case 'phone':
            description = `Finde Smartphones ohne Vertrag von Samsung, Apple Xiaomi, Motorola und anderen - vergleiche nach Preis, Bewertungen, Displaygröße, Marke, SD Karten Slot, Rating und Speicherplatz.`;
            break;
        case 'drive':
            description = `Finde Festplatten von Samsung, Crucial, Western Digital und anderen - vergleiche nach Preis, Bewertungen, Größe, Hersteller, Geschwindigkeit oder Preis pro Terrabyte.`;
            break;
        default:
            description = ``
    }

    let keywords = '';
    switch (type) {
        case 'phone':
            keywords = `Smartphone, Preise, Vergleich, Amazon, Handy, Mobiltelefon`;
            break;
        case 'drive':
            keywords = `Festplatten, Harddrives, Preise, Vergleich, Amazon`;
            break;
        default:
            keywords = ``;
    }

    let ogImage = '';
    if(teaserImage !== '') {
        ogImage = <meta name="og:image" content={teaserImage} />;

    }

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="keywords" content={keywords} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content={description} />
            <meta name="og:title" content={title} />
            <meta name="og:url" content={baseurl} />
            <meta name="twitter:title" content={title} />
            <meta property="og:type" content="website" />
            {ogImage}
        </Helmet>
    );
}

export default HtmlHeaderTags;