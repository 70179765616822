
//import { PublicUrlContext } from "../GlobalContext/PublicUrlContext";

import { Link } from "react-router-dom";

function Footer({ lastUpdated }) {

    //const publicUrl = useContext(PublicUrlContext);

    return (
        <>
            <footer className="bg-light pt-4 mt-4 pb-5">
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col pb-3">
                            <ul className="list-inline">
                                <li>
                                    <Link to={'/'}
                                        onClick={() => {
                                            window.scroll(0, 0);
                                        }}>
                                        Start
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/impressum'}
                                        onClick={() => {
                                            window.scroll(0, 0);
                                        }}>
                                        Impressum
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col border-top pt-5">
                            <p>
                                <strong>(*)</strong> - Die mit Sternchen (*) gekennzeichneten Verweise sowie Links auf Kundenrezensionen sind
                                sogenannte Provision-Links (Werbung). <br/>
                            </p>
                            <p>Als Amazon-Partner verdiene ich an qualifizierten Verkäufen</p>
                            <p>
                                Bildrechte: Soweit nicht anders angegeben, werden alle Bider direkt von Amazon geladen und unterliegen dem entsprechenden Urheberrecht.
                            </p>
                            <p>
                                Die letzte Aktualisierung der Daten war am  <strong>{lastUpdated}</strong>.

                                Produktpreise und Verfügbarkeit, die auf dieser Seite angezeigt werden, sind zum angegebenen
                                Zeitpunkt und können sich jederzeit ändern bzw. von den Preisen auf Amazon abweichen.
                                Daher gilt immer der Preis auf der Amazon Webseite.

                                BESTIMMTE INHALTE, DIE AUF DIESER WEBSITE ERSCHEINEN, STAMMEN DIREKT VON AMAZON.
                                DIESE INHALTE WERDEN "WIE BESEHEN" BEREITGESTELLT UND KÖNNEN JEDERZEIT GEÄNDERT ODER ENTFERNT WERDEN.

                                Alle anderen Inhalte sind urheberrechtlich geschützt. Alle Rechte vorbehalten.</p>
                        </div>
                    </div>
                </div>
            </footer >
        </>
    )
}
export default Footer;
