
import React, { useContext, useEffect, useState } from 'react';
import Table from "react-bootstrap/esm/Table";
//import Form from 'react-bootstrap/Form';
import { ApiUrlContext } from '../GlobalContext/ApiUrlContext';
import { Link, useNavigate } from 'react-router-dom'
import MyNavBar from './MyNavBar';
import Select from 'react-select';
import { Rating } from '@smastrom/react-rating'
import { formConfig } from '../data/formConfig.js';
import MyLoader from '../ComponentsShared/MyLoader.js';

//console.log('=== 1 phoneAttributes', formConfig);

function ResultTable() {

    const apiUrl = useContext(ApiUrlContext);
    const filename = apiUrl + 'hddrives/drivesJson.json';
    const [currentUrl, setCurrentUrl] = useState('');
    const [checkedItems, setCheckedItems] = useState({});
    const [formData, setFormData] = useState({});
    const [initialRender, setInitialRender] = useState(true);
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const [tableSorting, setTableSorting] = useState({});
    const [sortingState, setSortingState] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const params = new URLSearchParams(window.location.search);
    const [selectedOptions, setSelectedOptions] = React.useState([]); // react-select
    const [initialUrlSorting, setInitialUrlSorting] = useState(true);
    const [onlyTopResults, setOnlyTopResults] = useState(false);
    const preselectedOptions = {};

    const checkboxChange = (event) => {
        const { name, checked } = event.target;
        const dataPropertyName = event.target.getAttribute('data-property_name');
        console.log('=== 1 checkboxChange', name, checked, dataPropertyName);

        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [name]: checked
        }));

        let tempChecked = [];
        if (formData[dataPropertyName] !== "" && formData[dataPropertyName] !== undefined) {
            tempChecked = formData[dataPropertyName].split(',');
        }
        if (checked) {
            tempChecked.push(name);
        } else {
            tempChecked = tempChecked.filter(item => item !== name);
        }

        // create new ajax url
        let updatedFormData = formData;
        updatedFormData[dataPropertyName] = tempChecked.join(',');
        createUrl(updatedFormData, tableSorting);

        setFormData((prevFormData) => ({
            ...prevFormData,
            [dataPropertyName]: tempChecked.join(','),
            random: Math.random()
        }));
    }

    // START Checkbox replace with dropdowns START
    const handleSelectChange = (name) => (options) => {

        // convert values to comma separated string
        let values = [];
        let myCheckedItems = [];
        options.forEach(element => {
            values.push(element.value);
            myCheckedItems[element.value] = true;
        });

        const values_joined = values.join(',');
        let updatedFormData = formData;
        updatedFormData[name] = values_joined;

        createUrl(updatedFormData, tableSorting);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: values_joined,
            random: Math.random()
        }));
        console.log('=== 3 SELECT_HANDLER', name, values_joined, options);
        setSelectedOptions(options);
    }

    // define constants to fill and create the checkboxes
    // ----------------------------------------------------

    if (params.has('sorting')) {
        if (params.get('sorting') !== tableSorting.sorting) {
            if (initialUrlSorting) {
                //setTableSorting(params.get('sorting'));
                setTableSorting({ 'sorting': params.get('sorting'), 'random': Math.random() });
                setInitialUrlSorting(false);
            }
        }
    }

    /**
     * check if url contains parameters and load
     * them on inital load
     *
     * @param {*} propertyName The property name to check, i.e. stars, price, brand, storage, display_size
     * @param {*} initialUrl  Prevent endless loops (update url, call this function, update url, ...)
     * @param {*} setInitialUrl Store the initialUrl state -> set to false after first load
     */
    const checkInitialParameters = (propertyName, initialUrl, setInitialUrl) => {
        if (params.has(propertyName)) {
            if (params.has(propertyName) && params.get(propertyName) !== 'undefined') {
                if (params.get(propertyName) !== formData[propertyName]) {
                    if (initialUrl) {

                        setInitialUrl(false);

                        // read and set params for select dropdowns
                        formConfig.selectFormConfiguration.forEach(element => {
                            if (element.name === propertyName) {
                                preselectedOptions[propertyName] = [];
                                element.selectOptions.forEach(element2 => {
                                    if (params.get(propertyName).includes(element2.value)) {
                                        preselectedOptions[propertyName].push(element2);
                                    }
                                });
                            }
                        });

                        if (selectedOptions.length === 0 && Object.keys(preselectedOptions).length !== 0) {
                            setSelectedOptions(preselectedOptions);
                        }

                        // split propertyName -> value string into array
                        // stars=stars4,stars3 becomes ['stars4', 'stars3']
                        // and store as checked items to preselect checkboxes
                        let tempCheckedItems = checkedItems;
                        params.get(propertyName).split(',').forEach(element => {
                            checkedItems[element] = true;
                        });
                        setCheckedItems(tempCheckedItems);

                        // also store parameters in formdata
                        let formdataTemp = formData;
                        formdataTemp[propertyName] = params.get(propertyName);
                        setFormData(formdataTemp);
                    }
                }
            }
        }
    };

    const [initialUrlStars, setInitialUrlStars] = useState(true);
    const [initialUrlBrand, setInitialUrlBrand] = useState(true);
    const [initialUrlSizeGb, setInitialUrlSizeGb] = useState(true);
    const [initialUrlPrice, setInitialUrlPrice] = useState(true);
    const [initialUrlIsRefurbished, setInitialUrlIsRefurbished] = useState(true);
    const [initialUrlIsNew, setInitialUrlIsnew] = useState(true);
    const [initialUrlIsSsd, setInitialUrlIsSsd] = useState(true);
    const [initialUrlIsNvme, setInitialUrlIsNvme] = useState(true);
    const [initialUrlIsUsb, setInitialUrlIsUsb] = useState(true);
    const [initialUrlIsEnterprise, setInitialUrlIsEnterprise] = useState(true);
    const [initialUrlIsInternal, setInitialUrlIsInternal] = useState(true);
    const [initialUrlRpm, setInitialUrlRpm] = useState(true);
    const [initialUrlFormFactor, setInitialUrlFormFactor] = useState(true);
    const [initialUrlMaxSpeed, setInitialUrlMaxSpeed] = useState(true);
    checkInitialParameters('stars', initialUrlStars, setInitialUrlStars);
    checkInitialParameters('brand', initialUrlBrand, setInitialUrlBrand);
    checkInitialParameters('size_gb', initialUrlSizeGb, setInitialUrlSizeGb);
    checkInitialParameters('price', initialUrlPrice, setInitialUrlPrice);
    checkInitialParameters('is_refurbished', initialUrlIsRefurbished, setInitialUrlIsRefurbished);
    checkInitialParameters('is_new', initialUrlIsNew, setInitialUrlIsnew);
    checkInitialParameters('is_ssd', initialUrlIsSsd, setInitialUrlIsSsd);
    checkInitialParameters('is_nvme', initialUrlIsNvme, setInitialUrlIsNvme);
    checkInitialParameters('is_usb', initialUrlIsUsb, setInitialUrlIsUsb);
    checkInitialParameters('is_enterprise', initialUrlIsEnterprise, setInitialUrlIsEnterprise);
    checkInitialParameters('rpm', initialUrlRpm, setInitialUrlRpm);
    checkInitialParameters('form_factor', initialUrlFormFactor, setInitialUrlFormFactor);
    checkInitialParameters('max_speed', initialUrlMaxSpeed, setInitialUrlMaxSpeed);
    checkInitialParameters('is_internal', initialUrlIsInternal, setInitialUrlIsInternal);


    const createUrl = (myFormData, myTableSorting) => {
        //let sorting = 'total_reviews,desc';
        let sorting = '';
        let url = "/?sorting=total_reviews,desc";
        //let url = "/?sorting=total_reviews,desc&stars=stars5,stars4&price=price_300,price_400";
        if (myTableSorting.sorting !== undefined) {
            sorting = myTableSorting.sorting;
            url = "/?sorting=" + sorting;
        }
        if (Object.keys(myFormData).length !== 0) {
            if (myFormData.stars !== "" && myFormData.stars !== undefined) {
                url = url + "&stars=" + myFormData.stars;
            }
            if (myFormData.price !== "" && myFormData.price !== undefined) {
                url = url + "&price=" + myFormData.price;
            }
            if (myFormData.brand !== "" && myFormData.brand !== undefined) {
                url = url + "&brand=" + myFormData.brand;
            }
            if (myFormData.size_gb !== "" && myFormData.size_gb !== undefined) {
                url = url + "&size_gb=" + myFormData.size_gb;
            }
            if (myFormData.is_ssd !== "" && myFormData.is_ssd !== undefined) {
                url = url + "&is_ssd=" + myFormData.is_ssd;
            }
            if (myFormData.is_nvme !== "" && myFormData.is_nvme !== undefined) {
                url = url + "&is_nvme=" + myFormData.is_nvme;
            }
            if (myFormData.is_usb !== "" && myFormData.is_usb !== undefined) {
                url = url + "&is_usb=" + myFormData.is_usb;
            }
            if (myFormData.is_enterprise !== "" && myFormData.is_enterprise !== undefined) {
                url = url + "&is_enterprise=" + myFormData.is_enterprise;
            }
            if (myFormData.is_internal !== "" && myFormData.is_internal !== undefined) {
                url = url + "&is_internal=" + myFormData.is_internal;
            }
            if (myFormData.is_refurbished !== "" && myFormData.is_refurbished !== undefined) {
                url = url + "&is_refurbished=" + myFormData.is_refurbished;
            }
            if (myFormData.is_new !== "" && myFormData.is_new !== undefined) {
                url = url + "&is_new=" + myFormData.is_new;
            }
            if (myFormData.rpm !== "" && myFormData.rpm !== undefined) {
                url = url + "&rpm=" + myFormData.rpm;
            }
            if (myFormData.form_factor !== "" && myFormData.form_factor !== undefined) {
                url = url + "&form_factor=" + myFormData.form_factor;
            }
            if (myFormData.max_speed !== "" && myFormData.max_speed !== undefined) {
                url = url + "&max_speed=" + myFormData.max_speed;
            }
        }
        navigate(url);
        setCurrentUrl(url);
        console.log('TABLE SORTING changed', myTableSorting, sorting);
    }

    // ------------------------------------
    // load data from remote json file
    // ------------------------------------
    useEffect(() => {
        if (initialRender) {
            setSortingState({ 'total_reviews': 'desc' });
            setCurrentUrl(window.location.search);
            setInitialRender(false);
        } else {
            let urlWithoutFirstSlash = currentUrl.replace('/', '');
            console.log('=== 100 urlWithoutFirstSlash', urlWithoutFirstSlash);

            // check if url contains the word proxy
            if (filename.includes('proxy.php')) {
                console.log('=== 100 filename includes proxy.php');
                urlWithoutFirstSlash = encodeURIComponent(urlWithoutFirstSlash);
            }

            const urltemp = filename + urlWithoutFirstSlash;
            console.log('=== 100 filurltempename', urltemp);
            setIsLoading(true);
            fetch(urltemp, {
                method: 'GET'
            })
                .then(response => response.json())
                .then((result) => {
                    setItems(result.result);
                    setOnlyTopResults(result.only_top_results);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching dataFiles:', error);
                    setIsLoading(false);
                });
        }
    }, [filename, initialRender, currentUrl]);

    // handle click on thead th cells
    // ------------------------------------
    function onSortingclickHander(name) {

        console.log('=== 3 TABLE SORTING onSortingclickHander', tableSorting, sortingState);

        if (sortingState[name] === undefined) {
            //sortingState[name] = 'asc';
            sortingState[name] = 'desc';
            console.log('=== 3 TABLE SORTING onSortingclickHander if 1');
        }
        else if (sortingState[name] === 'desc') {
            sortingState[name] = 'asc';
            console.log('=== 3 TABLE SORTING onSortingclickHander if 2');
        }
        else if (sortingState[name] === 'asc') {
            sortingState[name] = 'desc';
            console.log('=== 3 TABLE SORTING onSortingclickHander if 3');
        }

        const newArray = Object.fromEntries(
            Object.entries(sortingState).filter(([key]) => key === name)
        );

        // create new ajax url
        let updatedTableSorting = tableSorting;
        updatedTableSorting.sorting = name + ',' + newArray[name];
        createUrl(formData, updatedTableSorting);

        setSortingState(newArray);
        setTableSorting({ 'sorting': name + ',' + newArray[name], 'random': Math.random() });
    }

    // get classname for thead th cells
    // (show sorting direction)
    // ------------------------------------
    const getClassNamesFor = (name) => {
        let className = "";
        if (sortingState[name]) {
            className = sortingState[name];
        }
        return className;
    };

    const [searchResultHeadline, setSearchResultHeadline] = useState(<></>);
    useEffect(() => {
        console.log('=== 1 useEffect', window.location.search);
        if (onlyTopResults) {
            console.log('=== 1 useEffect currentUrl empty');
            setSearchResultHeadline(<> Die {items.length} Top Empfehlungen. Bitte verwende die Suche für individuelle Ergebnisse.</>);
        } else {
            console.log('=== 1 useEffect currentUrl NOT EMPTY');
            setSearchResultHeadline(<>Die Suche ergab <strong>{items.length}</strong> Ergebnisse</>);

            if (items.length === 0) {
                setSearchResultHeadline(
                    <>
                        Die Suche ergab <strong>keine</strong> Ergebnisse, bitte wähle einen anderen Filter
                    </>
                );
            }
        }
    }, [currentUrl, items.length, onlyTopResults]);

    const loadPhoneDetails = (asin) => {
        console.log('=== 1 loadPhoneDetails', asin);
    }

    // END Checkbox replace with dropdowns END
    return (
        <>
            <MyNavBar />
            <MyLoader isLoading={isLoading} />
            <div className="container-fluid mb-4 mt-5">
                {/* FILTER FORM */}
                <div className="row ">
                    <div className="col bg-lightxx text-black px-5 pb-2">
                        <div className="ZZZ-sticky-top ">
                            <div className="sidebar-header">
                                <h1 className='h4 text-lg-centerx main-color'>
                                    Finde und vergleiche Festplatten
                                </h1>
                                <p className='text-lg-centerx'>
                                    <strong>
                                        Finde und vergleiche Festplatten nach Preis (pro TB/GB), Bewertungen, Größe, Geschwindigkeit und Typ.
                                    </strong>
                                </p>
                            </div>
                            <form>

                                {/** SELECT DROPDOWNS */}
                                <div className="row">
                                    {formConfig.selectFormConfiguration && Object.keys(formConfig.selectFormConfiguration).map((key, idx) => (
                                        <div className='col-lg-3 col-md-6 mt-3' key={idx}>
                                            <label htmlFor={formConfig.selectFormConfiguration[key].id} className="form-label">
                                                {formConfig.selectFormConfiguration[key].label}
                                            </label>
                                            <Select
                                                isMulti
                                                id={formConfig.selectFormConfiguration[key].name}
                                                name={formConfig.selectFormConfiguration[key].name}
                                                closeMenuOnSelect={false}
                                                options={formConfig.selectFormConfiguration[key].selectOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={handleSelectChange(formConfig.selectFormConfiguration[key].name)}
                                                placeholder={formConfig.selectFormConfiguration[key].placeholder_select + ' ...'}
                                                value={selectedOptions[formConfig.selectFormConfiguration[key].name]} />
                                        </div>
                                    ))}
                                </div>

                                {/** CHECKBOXES */}
                                {formConfig.checkboxFormConfiguration && Object.keys(formConfig.checkboxFormConfiguration).map((key, idx) => (
                                    <fieldset key={idx} className="row pt-2 mt-3 hover-effect">
                                        <div className="col-lg-auto">
                                            <legend className="col-form-label pt-0">
                                                <strong>{formConfig.checkboxFormConfiguration[key].label}</strong>
                                            </legend>
                                        </div>
                                        <div className='col-lg-auto'>
                                            {formConfig.checkboxFormConfiguration[key].source && Object.keys(formConfig.checkboxFormConfiguration[key].source).map((key2, idx2) => (
                                                <div key={idx2} className='form-check form-check-inline'>
                                                    <input type="checkbox" className="form-check-input" id={formConfig.checkboxFormConfiguration[key].source[key2].id} name={formConfig.checkboxFormConfiguration[key].source[key2].name}
                                                        data-property_name={formConfig.checkboxFormConfiguration[key].source[key2].propertyName}
                                                        checked={checkedItems[formConfig.checkboxFormConfiguration[key].source[key2].name] || false}
                                                        onChange={checkboxChange} />
                                                    <label className="form-label" htmlFor={formConfig.checkboxFormConfiguration[key].source[key2].id}>{formConfig.checkboxFormConfiguration[key].source[key2].label}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </fieldset>
                                ))}
                            </form>

                            {/** SPRTING */}
                            <div className='mt-2'>
                                <div className='row'>
                                    <div className='col-lg-auto mb-2'>
                                        <strong>Sortieren</strong>
                                    </div>
                                    <div className='col-lg-auto'>
                                        <ul className="list-inline sorting">
                                            <li className={'list-inline-item ' + getClassNamesFor('price_float')}>
                                                <a href="#0" onClick={() => onSortingclickHander('price_float')}>Preis</a>
                                                <i className="fas fa-arrow-up" />
                                                <i className="fas fa-arrow-down" />
                                            </li>
                                            <li className={'list-inline-item ' + getClassNamesFor('price_per_tb')}>
                                                <a href="#0" onClick={() => onSortingclickHander('price_per_tb')}>Preis je TB</a>
                                                <i className="fas fa-arrow-up" />
                                                <i className="fas fa-arrow-down" />
                                            </li>
                                            <li className={'list-inline-item ' + getClassNamesFor('stars')}>
                                                <a href="#0"
                                                    onClick={() => onSortingclickHander('stars')}>Sterne</a>
                                                <i className="fas fa-arrow-up" />
                                                <i className="fas fa-arrow-down" />
                                            </li>
                                            <li className={'list-inline-item ' + getClassNamesFor('total_reviews')}>
                                                <a href="#0" onClick={() => onSortingclickHander('total_reviews')}>Kundenrezensionen</a>
                                                <i className="fas fa-arrow-up" />
                                                <i className="fas fa-arrow-down" />
                                            </li>
                                            <li className={'list-inline-item ' + getClassNamesFor('drive_size_tb')}>
                                                <a href="#0" onClick={() => onSortingclickHander('drive_size_tb')}>Größe (TB)</a>
                                                <i className="fas fa-arrow-up" />
                                                <i className="fas fa-arrow-down" />
                                            </li>
                                            <li className={'list-inline-item ' + getClassNamesFor('max_speed')}>
                                                <a href="#0" onClick={() => onSortingclickHander('max_speed')}>MB/s</a>
                                                <i className="fas fa-arrow-up" />
                                                <i className="fas fa-arrow-down" />
                                            </li>
                                            <li className={'list-inline-item ' + getClassNamesFor('title')}>
                                                <a href="#0" onClick={() => onSortingclickHander('title')}>Titel</a>
                                                <i className="fas fa-arrow-up" />
                                                <i className="fas fa-arrow-down" />
                                            </li>
                                            <li className={'list-inline-item ' + getClassNamesFor('number_of_people_bought_int')}>
                                                <a href="#0" onClick={() => onSortingclickHander('number_of_people_bought_int')}>Verkäufe</a>
                                                <i className="fas fa-arrow-up" />
                                                <i className="fas fa-arrow-down" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <a className='btn btn-outline-primary btn-sm' href="/">Filter zurücksetzen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** RESULT TABLE */}
            <div className="container-fluid">
                <div className="col">
                    <div className='container-fluid'>
                        <div className='row align-items-center mb-3'>
                            <div className='col-12'>
                                <h2 className='h4'>Ergebnisse</h2>
                                <p>
                                    {searchResultHeadline} -
                                    die verlinkten Titel und Bewertungen öffnen die jeweilige Amazon Produktseite.
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col table-container'>
                                <Table hover responsive className={'result-table ' + (isLoading ? 'hidden' : 'fade-in')}>
                                    <caption>Suchergebnisse</caption>
                                    <thead>
                                        <tr>
                                            <th>
                                            </th>
                                            <th>
                                                <div className={getClassNamesFor('price_float')}>
                                                    <a href='#0' onClick={() => onSortingclickHander('price_float')}>
                                                        Preis
                                                    </a>
                                                    <i className="fas fa-arrow-up" />
                                                    <i className="fas fa-arrow-down" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className={getClassNamesFor('price_per_tb')}>
                                                    <a href='#0' onClick={() => onSortingclickHander('price_per_tb')}>
                                                        Preis je TB
                                                    </a>
                                                    <i className="fas fa-arrow-up" />
                                                    <i className="fas fa-arrow-down" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className={getClassNamesFor('drive_size_tb')}>
                                                    <a href='#0' onClick={() => onSortingclickHander('drive_size_tb')}>
                                                        Größe
                                                    </a>
                                                    <i className="fas fa-arrow-up" />
                                                    <i className="fas fa-arrow-down" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className={getClassNamesFor('max_speed')}>
                                                    <a href='#0' onClick={() => onSortingclickHander('max_speed')}>
                                                        Speed
                                                    </a>
                                                    <i className="fas fa-arrow-up" />
                                                    <i className="fas fa-arrow-down" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className={getClassNamesFor('total_reviews')}>
                                                    <a href='#0' onClick={() => onSortingclickHander('total_reviews')}>
                                                        Rezensionen
                                                    </a>
                                                    <i className="fas fa-arrow-up" />
                                                    <i className="fas fa-arrow-down" />
                                                </div>
                                                <div className={getClassNamesFor('stars')}>
                                                    <a href='#0' onClick={() => onSortingclickHander('stars')}>
                                                        Sterne
                                                    </a>
                                                    <i className="fas fa-arrow-up" />
                                                    <i className="fas fa-arrow-down" />
                                                </div>
                                            </th>

                                            <th>
                                                <div className={getClassNamesFor('title')}>
                                                    <a href='#0' onClick={() => onSortingclickHander('title')}>
                                                        Titel
                                                    </a>
                                                    <i className="fas fa-arrow-up" />
                                                    <i className="fas fa-arrow-down" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className={getClassNamesFor('number_of_people_bought_int')}>
                                                    <a href='#0' onClick={() => onSortingclickHander('number_of_people_bought_int')}>
                                                        Verkäufe
                                                    </a>
                                                    <i className="fas fa-arrow-up" />
                                                    <i className="fas fa-arrow-down" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items && Object.keys(items).map((key, idx) => (
                                            <tr key={idx}>
                                                <td className='text-left no-wrap'>
                                                    <a href={items[key].url} target="_blank" rel="noreferrer" title={"Externer Link zum Produkt " + items[key].title}>
                                                        <img
                                                            src={items[key].image}
                                                            //src={apiUrl + '/imageProxy/teaser_image/' + items[key].id}
                                                            alt={items[key].title}
                                                            title={items[key].title}
                                                            style={{ maxWidth: '50px', maxHeight: '60px' }}
                                                            onMouseOver={() => loadPhoneDetails(items[key].asin)} />
                                                    </a>
                                                    {/* <br /><small>Bild: {items[key].image_domain}</small>*/}
                                                </td>
                                                <td>
                                                    {items[key].price}
                                                </td>
                                                <td className='no-wrap'>
                                                    {parseFloat(items[key].price_per_tb) > 0 && items[key].price_per_tb}<br />
                                                    {parseFloat(items[key].price_per_gb) > 0 && items[key].price_per_gb}
                                                </td>
                                                <td>
                                                    {(parseFloat(items[key].drive_size_tb) > 0) && items[key].drive_size_tb}
                                                </td>
                                                <td>
                                                    {items[key].max_speed > 0 && items[key].max_speed + 'MB/s'}
                                                </td>
                                                <td>
                                                    <a href={items[key].reviews_url} className='underline' target="_blank" rel="noreferrer" title={"Externer Link zu den Bewertungen für " + items[key].title}>
                                                        {items[key].total_reviews}
                                                    </a>
                                                    <br />
                                                    {/*{items[key].stars}*/}
                                                    <Rating style={{ maxWidth: 60 }} value={items[key].stars} readOnly={true} />
                                                </td>

                                                <td className='td-title'>
                                                    <a href={items[key].url} target="_blank" rel="noreferrer" title={"Externer Link zum Produkt " + items[key].title}>
                                                        {items[key].title}
                                                    </a> (*)
                                                    <br />
                                                    <small>
                                                        Preis: <strong>{items[key].price}</strong> |
                                                        Bewertungen: <strong>{items[key].total_reviews}</strong> |
                                                        Sterne: {items[key].stars}
                                                        {(items[key].brand && items[key].brand !== '') && ' | Brand: ' + items[key].brand}
                                                        {(parseFloat(items[key].drive_size_tb) > 0) && ' | Größe: ' + items[key].drive_size_tb}
                                                        {(parseFloat(items[key].rpm) > 0) && ' | ' + items[key].rpm + ' Rpm'}
                                                        {(parseFloat(items[key].max_speed) > 0) && ' | ' + items[key].max_speed + ' MB/s'}
                                                        {(parseFloat(items[key].price_per_tb) > 0) && ' | ' + items[key].price_per_tb + ' - ' + items[key].price_per_gb}
                                                        {(items[key].is_ssd && items[key].is_ssd === true) && ' | SSD'}
                                                        {(items[key].is_usb && items[key].is_usb === true) && ' | USB'}
                                                        {(items[key].is_enterprise && items[key].is_enterprise === true) && ' | 24/7'}
                                                        {(items[key].form_factor > 0) && ' | ' + items[key].form_factor + ' Zoll'}
                                                        {(items[key].is_refurbished && items[key].is_refurbished === true) && ' | Refurbished'}
                                                        <br />
                                                        <span className='hidden'>{(items[key].last_update && items[key].last_update !== '') && 'Preisupdate: ' + items[key].last_update}</span>
                                                        {(items[key].availability_status && items[key].availability_status !== '') && ' | ' + items[key].availability_status}
                                                    </small>

                                                    <p className='text-end mt-1'>
                                                        <Link className='font-weight-normal' to={'/viewdrive/' + items[key].id} target='_blank'>
                                                            Mehr ...
                                                        </Link>
                                                    </p>
                                                    {/*

                                                    {(items[key].higlighted_keywords && items[key].higlighted_keywords !== '') &&
                                                        <p className='mt-1'><small><strong>Weitere Stichpunkte</strong>: {items[key].higlighted_keywords}</small></p>}
                                                    */}

                                                </td>
                                                <td>{items[key].number_of_people_bought}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default ResultTable;